<template>
  <form action="#" @submit.prevent="doContact" class="form">
    <div class="form-group" :class="{ error: v$.form.name.$errors.length }">
      <label>Your Name/Company Name <span class="required-star">*</span></label>
      <input type="text" class="form-control" v-model="v$.form.name.$model" />
      <div
        class="input-errors"
        v-for="(error, index) of v$.form.name.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group" :class="{ error: v$.form.mc.$errors.length }">
      <label>MC/USDOT# <span class="required-star">*</span></label>
      <input type="text" class="form-control" v-model="v$.form.mc.$model" />
      <div
        class="input-errors"
        v-for="(error, index) of v$.form.mc.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
      <label>Your Email <span class="required-star">*</span></label>
      <input type="text" class="form-control" v-model="v$.form.email.$model" />
      <div
        class="input-errors"
        v-for="(error, index) of v$.form.email.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group" :class="{ error: v$.form.phone.$errors.length }">
      <label>Your Phone# <span class="required-star">*</span></label>
      <input
        type="text"
        class="form-control"
        @input="acceptPhoneNumber()"
        v-model="v$.form.phone.$model"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.form.phone.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="form-group">
      <label
        >What can we help you with <span class="required-star">*</span></label
      >
      <select class="custom-select" v-model="form.help">
        <option value="Account" selected>Update Carrier Account</option>
        <option value="Payment">Payment Not Received</option>
        <option value="Invoice">Carrier Invoice Not Uploading</option>
        <option value="Comcheck">Comcheck Expired</option>
      </select>
    </div>
    <div class="form-group" :class="{ error: v$.form.comments.$errors.length }">
      <label>Comments <span class="required-star">*</span></label>
      <textarea
        type="textarea"
        class="form-control textarea"
        rows="2"
        cols="50"
        v-model="v$.form.comments.$model"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.form.comments.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <button class="main-btn">Send Message</button>
  </form>
  <div class="success-msg" v-if="isSuccessfull">{{ responseText }}</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, email, helpers, minLength } from "@vuelidate/validators";
export default {
  data() {
    return {
      state: false,
      isSuccessfull: false,
      responseText: "",
      v$: useVuelidate(),
      selected: "",
      form: {
        name: this.$store.state.login.FirstName,
        mc: this.$store.state.login.MCNumber,
        email: this.$store.state.login.EmailAddress,
        phone: this.$store.state.login.PhoneNumber,
        help: "Account",
        comments: "",
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage(
            "Your name/company name is required",
            required
          ),
        },
        mc: {
          required: helpers.withMessage("MC/USDOT # is required", required),
        },
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Please enter a valid email", email),
        },
        phone: {
          required: helpers.withMessage("Phone number is required", required),
          minLength: helpers.withMessage(
            "Please enter a valid phone number",
            minLength(14)
          ),
        },
        help: {
          required,
        },
        comments: {
          required: helpers.withMessage("Comments are required", required),
        },
      },
    };
  },
  methods: {
    acceptPhoneNumber() {
      var x = this.form.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.form.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async doContact() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      try {
        // Send login action to store
        let response = await this.TryDoContact({
          name: this.form.name,
          mc: this.form.mc,
          email: this.form.email,
          phone: this.form.phone,
          help: this.form.help,
          comments: this.form.comments,
        });

        this.responseText = response.ResponseText;
        this.isSuccessfull = response.IsSuccessful;
        setTimeout(() => (this.isSuccessfull = false), 3000);
        // Send user to track loads page if login successful
      } catch (err) {
        console.log(err);
      }
    },
    ...mapActions("contact", ["TryDoContact"]),
  },
  computed: {
    ...mapGetters({
      initialLoginType: "environment/LoginType",
      apiKey: "environment/APIUrl",
      dataFetched: "environment/Fetched",
    }),
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>
<style lang="scss">
  .form-group {
    margin-bottom: 20px;
    &.error {
      .form-control {
        border: 1px solid #ff073a;
        margin-bottom: 5px;
      }
      .error-msg {
        color: #ff073a;
      }
    }
}
.success-msg {
  background: #5ad568ee;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;
}
.form-group {
  margin-bottom: 10px;
}
.form-control {
  padding: 0.275rem 0.75rem;
}
</style>
